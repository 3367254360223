/**
 * QA Environment properties file.
 */
export const environment = {
  production: false
};

/**
 * Application URLs.
 */
export const APP_URL = {
  API: 'https://learnqa.uniathena.com/athenaqa/api/',
  IP_GEOLOCATION: 'https://extreme-ip-lookup.com/json/?key=Q2XTHdI7x9BFavSEj8pj',
  OLD_LMS: 'https://ulearn.schneidestaging.in/', //confirm
  OLD_LMS_API: 'https://agestagingapi.azurewebsites.net/api/', //confirm
  WEBSITE: 'https://uniathena.com/', //confirm
  NEW_LMS: 'https://qa.uniathena.com/lms/',
  ACADS_PAYMENT_API: 'https://agestagingapi.azurewebsites.net/',
  WEBSITE_ENCRYPT_API: 'https://athenawpapi.azurewebsites.net/Encript/GetEncriptValue/',
  BASE_HREF_URL: 'https://qa.uniathena.com'
};

/**
 * Application configurations.
 */
export const APP_CONFIGURATION = {
  ADMIN: 24,
  CATEGORY_ID: 74,
  DISCUSSION_BOARD_ADMIN: 22,
  GUEST: 6
};

