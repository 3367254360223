<div class="athena-header col-md-12">
  <div class="logo col-2"
       (click)="redirectToHome()">
    <img src="assets/uniathena-logo.svg" />
  </div>
    <div class="navigation col-10">
  </div>
</div>



