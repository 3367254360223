import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {ApplicationConstant} from '../shared/constant/app.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    constructor(private baseService: BaseService) {
    }

    registerUser(data: any) {
        return this.baseService.post(ApplicationConstant.endpoint.REGISTER_URL, data)
            .toPromise()
            .then(response => response)
            .catch();
    }

    registerViaSocialLogin(data: any) {
        return this.baseService.post(ApplicationConstant.endpoint.REGISTER_VIA_SOCIAL_LOGIN_URL, data)
            .toPromise()
            .then(response => response)
            .catch();
    }

    authenticateUser(data: any) {
        return this.baseService.post(ApplicationConstant.endpoint.LOGIN_URL, data)
            .toPromise()
            .then(response => response)
            .catch();
    }

}
