/**
 * Routing of the application.
 */
export const RoutingConstant = {

  ADMIN: '/admin',
  ADMIN_DASHBOARD: '/admin/dashboard',

  BURSARY: '/admin/application/bursary',

  CHILD_MODULE: '/admin/child',
  CK_EDITOR_PAGE: `/ck-editor-discussion`,
  CK_EDITOR_SUCCESS: `/ck-editor-success`,
  COURSE_MODULE: '/admin/course',
  COURSE_APPLICATION_FAQ: '/admin/course/faq',
  COURSE_APPLICATION_OTHER_DETAILS: '/admin/course/other-details',
  CONFIGURATION: '/admin/configuration',

  DISCUSSION_CATEGORY: '/admin/create-discussion-category',

  EXEMPTION: '/admin/application/exemption',

  FAQ: `/admin/faq`,
  FEEDBACK_DETAILS: '/admin/feedback',
  FEEDBACK_HISTORY: '/admin/feedback/history',
  FEEDBACK_NEW_FORM: '/admin/feedback/new-form',
  FEEDBACK_NEW_FORM_WITH_ID: (formId: number, paramType: string) => 
  `/admin/feedback/new-form?${paramType}=${formId}`,

  INVOICE_CREATE_WITH_INVOICE_ID: (invoiceId: string) => `/admin/invoice/${invoiceId}`,
  INVOICE_CREATE_LIST: `/admin/invoice/invoice-create`,
  INVOICE_DETAILS: `/admin/invoice`,
  INVOICE_GENERATED_LIST: `/admin/invoice/invoice-generated`,
  
  PARENT_MODULE: '/admin/parent',
  
  PUSH_NOTIFICATION: '/admin/push-message',
  PUSH_NOTIFICATION_ADD: '/admin/push-message/add',
  PUSH_NOTIFICATION_SCHEDULE: '/admin/push-message/schedule',
  PUSH_NOTIFICATION_TOGGLE: '/admin/push-message/toggle',

  QUICK_APPLICATION: '/quick-application',

  RECEIPT_CREATE_WITH_ID: (receiptId: string) => `/admin/receipt/${receiptId}`,
  RECEIPT_DETAILS: `/admin/receipt`,

  SETTLEMENT: `/admin/settlement`,
  SETTLEMENT_DETAILS: `/admin/settlement/details`,
  SETTLED_PAYMENTS: `/admin/settlement/settled-payments`,
  TESTIMONIAL: `/admin/testimonial`,
  SETTLEMENT_WITH_ORDER_ID: (orderId: string, currencyId: number) =>
   `/admin/settlement/${orderId}/${currencyId}`,
  SETTLEMENT_WITH_UID_CID: (uid: string, cid: string, currencyId: number) => `/admin/settlement/${uid}/${cid}/${currencyId}`

}
